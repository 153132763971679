@import url('https://fonts.googleapis.com/css?family=Merriweather|Inconsolata|Nunito|Montserrat|Raleway|Julius+Sans+One');

:root {
    --pearl: #ececec;
    --teal: #9fd3c7;
    --mid-blue: #385170;
    --deep-blue: #142d4c;
}

html {
    font-size: 16px;
    /*
    overflow-x: hidden;
    margin-right: calc(100% - 100vw);
    */
}
body {
    width: 100%;
    margin: 0;
    padding: 0;
    font-family: Helvetica, 'Merriweather', 'Inconsolata', 'Nunito', 'Montserrat', 'Raleway', 'Julius+Sans+One', Tahoma, Verdana, Arial, sans-serif;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    /* background-color: #000; */
    background-color: #5476a8;
    /*background-color: #303030;*/
}










/*---==|>  .nav  <|==---*/
.nav {
    display: grid;
    grid-template-columns: auto auto auto auto auto;
    grid-column-gap: 0;
    justify-content: center;
    width: 90%;
    height: auto;
    padding: 0;
    margin: 1vw auto;
    /* border: 1vw solid #999; */
}
.nav a {
    text-align: center;
    text-decoration: none;
    /* font-family: 'Merriweather', sans-serif; */
    font-size: 2vw;
    color: #000;
    background-color:#F1F1F1;
    padding: 2vw 4vw;
    margin: 0;
}
.nav a.active {
    background-color: #88aadd;
    color: white;
}
.nav a:hover.active {
    background-color: #6994d4;
    color: white;
}
.nav a:hover:not(.active) {
    background-color: #bbbbbb;
    color: black;
}



/*---==|>  .pageWrapper  <|==---*/
/* .pageWrapper {
    width: 80%;
    background-color: #303030;
} */



/*---==|>  .page  <|==---*/
.page {
    color: #FFFFFF;
    width: 60%;
    background-color: #222;
    padding: 1vw 0;
    margin: .5vw auto;
}
.page h1 {
    /* font-family: 'Julius+Sans+One', sans-serif; */
    font-size: 32pt;
    text-align: center;
    color: #FFFFFF;
    /*text-shadow: 2pt 2pt #111111;*/
    padding: .5vw;
    margin: 0;
}
.page h2 {
    /* font-family: sans-serif; */
    font-size: 12pt;
    text-align: center;
    color: #999;
    padding: .5vw;
    margin: 0;
}
.page h3 {
    /* font-family: sans-serif; */
    font-size: 18pt;
    text-align: left;
    color: #FFFFFF;
    padding: .5vw 4vw;
    margin: 0;
}
.page p {
    /* font-family: 'Montserrat', 'Raleway', sans-serif; */
    font-size: 12pt;
    font-style: normal;
    line-height: 1.4;
    white-space: pre-wrap; /*pre-line*/
    text-align: justify;
    color: #FFFFFF;
    padding: 1vw 2vw;
    margin: 0;
}



/*---==|>  .gallery  <|==---*/
.gallery {
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
    /* grid-column-gap: 0; */
    justify-content: center;
    background-color: #353438;
    margin: 0 1%;
    /* align-items: center; */
    /* flex-direction: column; */
    /* width: 70%; */
    /* height: auto; */
    /* padding: 0; */
    /* margin: 1vw auto; */
    /* border: 1vw solid #999; */
}
.gallery h1 {
    margin: 5px auto;
    font-size: 18pt;
}
.gallery .card {
    display: grid;
    grid-template-rows: auto auto auto;
    width: 87%;
    font-size: 8pt;
    /* padding: 25px 4%; */
    /* margin: 4%; */
}
.gallery .card h1 {
    font-size: 14pt;
}
.gallery .card h2 {
    font-size: 10pt;
    color: #999;
}



/*---==|>  .card  <|==---*/
.card {
    width: 80%;
    padding: 25px 5%;
    margin: 12px auto;
    background-color: #3b3b3e;
}
.card h1 {
    font-family: 'Merriweather', sans-serif;
    font-size: 18pt;
    text-align: center;
    color: #FFF;
    padding: .5vw;
}
.card h2 {
    font-family: 'Merriweather', sans-serif;
    font-size: 12pt;
    text-align: center;
    color: #aaa;
    text-shadow: 1pt 1pt #222;
    padding: .3vw;
    padding-bottom: 15px;
    margin: 0;
}
.card h3 {
    font-family: 'Merriweather', sans-serif, 'Raleway', sans-serif;
    font-size: 12pt;
    text-align: left;
    color: #aaa;
    text-shadow: 1pt 1pt #222;
    /* padding: .3vw; */
    padding-bottom: 15px;
}
.card p {
    font-family: 'Merriweather', sans-serif;
    font-size: 11pt;
    font-style: normal;
    line-height: 1.6;
    color: #bbb;
    padding: .3vw;
    padding-bottom: 10px;
    text-align: justify;
}
.card blockquote {
    color: #ccc;
    text-align: justify;
}



/* Link */
.card a:link {
    color: #ffffff;
}
.card:hover {
    color: #9cb3c2;
}
.card:active {
    color: #84a9d6;
}
.card a:visited {
    color: #517aab;
}



/* Media */
audio {
    display: flex;
    margin: auto;
}
video, iframe.youtube {
    display: flex;
    margin: auto;
    padding: 2%;
    box-sizing: border-box;
    background-color: #333;
}
video.landscape, iframe.youtube {
    aspect-ratio: 16 / 9;
}
video.portrait {
    aspect-ratio: 9 / 16;
}
video, iframe.youtube {
    position: relative;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 95%;
}



/*---==|>  .art  <|==---*/
/* .art {
    width: 70%;
    padding: 0;
    margin: auto;
}
.art h1 {
    font-family: 'Merriweather', sans-serif;
    font-size: 18pt;
    text-align: center;
    color: #FFF;
    padding: .5vw;
}
.art h2 {
    font-family: 'Merriweather', sans-serif;
    font-size: 12pt;
    text-align: center;
    color: #aaa;
    padding: .3vw;
    margin: 0;
}
.art h3 {
    font-family: 'Merriweather', sans-serif, 'Raleway', sans-serif;
    font-size: 12pt;
    text-align: left;
    color: #aaa;
    text-shadow: 1pt 1pt #222;
    padding: .3vw;
}
.art p {
    font-family: 'Merriweather', sans-serif;
    font-size: 11pt;
    font-style: normal;
    line-height: 1.6;
    color: #aaa;
    padding: .3vw;
} */



/*---==|>  .img  <|==---*/
.img {
    display: grid;
    grid-template-columns: auto auto auto auto;
    grid-column-gap: .5vw;
    justify-content: center;
    width: 90%;
    height: auto;
    padding: 1vw;
    margin: 0 auto;
}
.img img {
    max-width: 100%;
    max-height: 17vw;
    padding: 0;
    margin: 0;
}



/*---==|>  .form  <|==---*/
.form {
    display: block;
    padding: 1vw;
    margin: 0;
    color: white;
}
.form input {
    width: 70%;
    padding: .5vw;
    margin: 0;
    box-sizing: border-box;
    border: 1px solid black;
}



/*---==|>  .audio  <|==---*/
/*
.audio {
    border: 1px solid lighten(#acacac, 20%);
    text-align: center;
    display: flex;
    flex-flow: row;
    margin: 4rem 0 4rem 0;
    width: 600px;
}
.audio progress {
    width: 90%;
}
.audio progress[value] {
    -webkit-appearance: none;
    appearance: none;
    background-color: white;
    color: blue;
    height: 5px;
}
.audio progress[value]::-webkit-progress-bar {
    background-color: white;
    border-radius: 2px;
    border: 1px solid lighten(#acacac, 20%);
    color: blue;
}
.audio progress[value]::-webkit-progress-value {
    background-color: blue;
}
.audio p {
    font-size: 1.6rem;
}
#playButton {
    background-size: cover;
    width: 75px;
    height: 75px;
    margin: 2rem 0 2rem 2rem;
    &.pause {
        background-color: red;
    }
}
*/



/*---==|>  1200px = 16font * 75em  <|==---*/
@media only screen and (max-width: 1200px) {
    
    .page {
        width: 80%;
        font-size: 100%;
    }
    /*
    .page p {
        font-size: 100%;
    }
    */
    
    .card {
        width: 80%;
    }
    .card h1 {
        font-size: 2vw;
    }
    .card video, .card iframe.youtube {
        width: 80%;
    }

    .gallery {
        grid-template-columns: 50% 50%;
    }
    
}
/*---==|>  900px = 16font * 56.25em  <|==---*/
@media only screen and (max-width: 900px) {
    .page {
        width: 90%;
        font-size: 100%;
    }
    /*
    .page h1 {
        font-size: 100%;
    }
    .page p {
        font-size: 80%;
    }
    */
    /* .card {
        
    } */
    /* .card video, .card iframe.youtube {
        width: 80%;
    } */
    /*
    .art h1 {
        font-size: 80%;
    }
    */
}
/*---==|>  600px = 16font * 37.5em  <|==---*/
@media only screen and (max-width: 37.5em) {
    .nav {
        display: grid;
        grid-template-columns: auto auto;
        grid-template-rows: auto auto;
        justify-content: center;
        padding: .5vw;
        margin: auto;
    }
    .nav a {
        font-size: 5vw;
    }
    .page {
        width: 96%;
        padding: .1vw 0;
    }
    .page h1 {
        font-size: 18pt;
    }
    .page h2 {
        margin: 5px auto;
    }
    .page p, .page blockquote {
        font-size: 70%;
    }
    .card {
        width: 96%;
        padding: 1%;
        margin-top: 1%;
        margin-bottom: 1%;
    }
    /* .card h2 {
        font-size: 70%;
        padding: 1.5vw;
    } */
    .card p {
        font-size: 70%;
        padding: 1.5vw;
    }
    .card video, .card iframe.youtube {
        width: 80%;
    }

    .gallery {
        grid-template-columns: 100%;
    }
}
